// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

//= require jquery3
//= require jquery_ujs
//= require popper
//= require bootstrap-sprockets
import * as bootstrap from "bootstrap"

import Trix from "trix"

document.addEventListener("trix-before-initialize", (event) => {
  // Change Trix.config if you need
  autoSaveDraft() // initialize auto save interval
  countWords()
  event.target.addEventListener("keydown", (event) => { countWords() })
})

document.addEventListener("trix-change", (event) => {
  autoSaveChanges = true
})

var autoSaveChanges = false

const autoSaveDraft = () => {
  setInterval(() => {
    if (autoSaveChanges) {
      autoSaveChanges = false
      document.getElementsByName('draft')[0].click()
    }
  }, 15000)
}

const countWords = () => {
  const words = document.getElementById('message_content')?.value
    .replaceAll(/<br>|<[\/]?div>|&nbsp;/g, ' ')
    .split(' ')
    .filter((el) => el != '')
    .length;

  const wordCountDiv = document.getElementById('word-count')
  if (wordCountDiv) {
    wordCountDiv.innerHTML = words;
  }
}
